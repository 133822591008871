<template>
  <div class="card-sections-block-header">
    <el-row type="flex" justify="space-between" style="margin-bottom: 20px">
      <div style="font-size: 24px">
        Индивидуальные цели
      </div>
<!--      <div-->
<!--        v-if="currentStage && currentStage.type !== 'assessment'"-->
<!--      >-->
<!--        <clone-elements-from-card-modal-->
<!--          v-if="card.chiefs_card_id && card.chiefs_card_id.direct"-->
<!--          :card-id="card.chiefs_card_id.direct"-->
<!--          @elements-cloned="reloadCard"-->
<!--        >-->
<!--          <el-button-->
<!--            slot="reference"-->
<!--            size="mini"-->
<!--            icon="far fa-clone"-->
<!--          >-->
<!--            Добавить цель из карты АР-->
<!--          </el-button>-->
<!--        </clone-elements-from-card-modal>-->
<!--        <clone-elements-from-card-modal-->
<!--          style="margin-left: 20px"-->
<!--          v-if="card.chiefs_card_id && card.chiefs_card_id.functional"-->
<!--          :card-id="card.chiefs_card_id.functional"-->
<!--          @elements-cloned="reloadCard"-->
<!--        >-->
<!--          <el-button-->
<!--            slot="reference"-->
<!--            size="mini"-->
<!--            icon="far fa-clone"-->
<!--          >-->
<!--            Добавить цель из карты ФР-->
<!--          </el-button>-->
<!--        </clone-elements-from-card-modal>-->
<!--      </div>-->
      <div>

<!--        <corporate-targets-modal-->
<!--          style="display: inline-block"-->
<!--        >-->
<!--          <el-button-->
<!--            slot="reference"-->
<!--            style="margin-left:35px"-->
<!--            type="text"-->
<!--            size="mini"-->
<!--            icon="fas fa-bullseye"-->
<!--          >-->
<!--            Корпоративные цели-->
<!--          </el-button>-->
<!--        </corporate-targets-modal>-->


        <history-modal
          v-if="$canAndRulesPass(card, 'card.see_history')"
          style="display: inline-block; margin-left:35px"
          title="История изменений карты"
          width="70%"
          show-model-full-name
          :method="$api.card.getCardHistory"
          :params="{  id: card.id }"
        >
          <el-button
            slot="reference"
            type="text"
            size="mini"
            icon="fas fa-history"
          >
            История изменений
          </el-button>
        </history-modal>



        <el-button
          style="margin-left:35px"
          type="text"
          size="mini"
          icon="fas fa-file-excel"
          @click="exportToExcel"
        >
          Экспорт в XLSX
        </el-button>

<!--        <start-dynamic-stage-button-->
<!--          v-if="stages.length < 3 && $canAndRulesPass(card, $permissions.CARD_STAGE.CREATE_DYNAMIC)"-->
<!--          style="margin-left:35px"-->
<!--          :card="card"-->
<!--          stage-type="correction"-->
<!--        >-->
<!--          Запустить корректировку-->
<!--        </start-dynamic-stage-button>-->
      </div>
    </el-row>
  </div>
</template>

<script>

import SearchInput from "@/components/filters/SearchInput";
import CardsYearSelect from "@/components/filters/cards/CardsYearSelect";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";
import CardsPeriodSelect from "@/components/filters/cards/CardsPeriodSelect.vue";
import UserSelect from "@/components/filters/users/UserSelect.vue";
import CardsStatusSelect from "@/components/filters/cards/CardsStatusSelect.vue";
import {mapGetters} from "vuex";
import {downloader} from "@/mixins/downloader";
import CloneElementsFromCardModal from "@/components/cards/CloneElementsFromCardModal.vue";
import HistoryModal from "@/components/HistoryModal.vue";
import StartDynamicStageButton from "@/components/cards/StartDynamicStageButton.vue";

export default {
  name: "card-sections-block-header",
  mixins: [downloader],
  components: {StartDynamicStageButton, HistoryModal, CloneElementsFromCardModal},
  props: {},

  computed: {
    ...mapGetters('card', {
      card: 'card',
    }),
    ...mapGetters('card/stages', {
      stages:'stages',
      currentStage: 'currentStage',
      currentNegotiation: 'currentNegotiation',
    }),
  },
  watch: {},
  data() {
    return {

    }
  },
  methods: {
    exportToExcel() {
      this.downloadFile(
        'export.xlsx',
        this.$api.card.exportToXls,
        {id: this.card.id}
      )
    },
  }
}
</script>


<style lang="scss">

</style>